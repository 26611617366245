.filter-box {
  display: flex;
  /* margin-right: 22px; */
}

.form-select {
  width: 100%;
}

.inputGrp {
  width: 45%;
}

.MuiPaper-root {
  z-index: 9999999 !important;
}

.MuiPopover-root {
  z-index: 9999999 !important;
}

@media screen and (max-width: 768px) {
  .d-flex {
    display: flex;
    flex-direction: column;
  }
}

select {
  border-color: #00000065 !important;
  /* background-color: green !important; */
}

.filter-box {
  outline: 2px solid blue; /* Always show blue outline */
  transition: outline 0.2s ease-in-out; /* Smooth transition for outline */
}

.filter-box:focus {
  outline: 2px solid blue; /* Ensure blue outline remains on focus */
}

.filter-box:hover {
  outline: 2px solid blue; /* Ensure blue outline remains on hover */
}

.filter-box:active {
  outline: 2px solid blue; /* Ensure blue outline remains on active state */
}
