.Home_Banner_Wrap {
  position: relative;
  height: 100%;
  background-color: #fff;
}
.Home_Banner_item_img {
  min-height: calc(100vh - 200px);
  max-height: 700px;
  background-color: #fff;
  height: 100%;
}
.Home_Banner_Img img {
  min-height: calc(100vh - 200px);
  max-height: 700px;
  height: 100%;
}

.HomeBannerSlider {
  margin-top: 120px;
  color: black !important;
  height: 100vh;
}
.Home_Banner_Info {
  max-width: calc(35% - 15px);
  width: 100%;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  z-index: 14;
}

.note-text {
  font-size: 0.7rem;
  font-weight: 600;
  color: "#fff";
}

.Home_Banner_Img {
  max-width: calc(60% - 15px);
  width: 100%;
  overflow: hidden;
  height: 100%;
  position: relative;
  z-index: 9;
  margin-left: auto;
}

.Home_Banner_Img img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.Home_Banner_Img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #fff 1.45%, rgba(255, 255, 255, 0) 59.35%);
}

.Home_Banner_Info h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  margin-bottom: 20px;
}

.Home_Banner_Info p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  max-width: 500px;
}
.slick-next {
  right: 25px;
  z-index: 9;
  width: 50px;
  height: 50px;
  background: #043189 !important;
  border-radius: 100%;
}

.slick-next:before {
  color: #043189;
  opacity: 1;
  line-height: unset;
  font-size: 30px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(1.3);
  content: url("https://api.iconify.design/grommet-icons/link-next.svg?color=white");
}
.btn-homeSearch .btn {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  border-radius: 4px;
}
.CustomDropDown .dropdown-toggle {
  padding: 0 10px;
  color: #000000;
  opacity: 0.6;
  font-weight: 600;
}
.CustomDropDown .dropdown-toggle:focus {
  outline: none;
  border: none;
}
@media (max-width: 1599px) {
  .Home_Banner_Info {
    max-width: calc(45% - 15px);
  }
  .Home_Banner_Info h1 {
    font-size: 54px;
  }
}
@media (max-width: 1599px) {
  .Home_Banner_Info h1 {
    font-size: 48px;
  }
}

@media (max-width: 1199px) {
  .HomeIndustries .col-lg-5 {
    margin-bottom: 15px;
  }
}
@media (max-width: 991px) {
  .Home_Banner_Img {
    max-width: 100%;
  }

  .Home_Banner_Info {
    max-width: 70%;
  }

  .Home_Banner_Img::after {
    background: #000000b5;
  }

  .Home_Banner_Info h1,
  .Home_Banner_Info p {
    color: #fff !important;
  }
  .Home_Banner_Info h1 {
    font-size: 38px;
  }

  .Home_Banner_Info p {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .HomeIndustries .col-lg-5 {
    width: 50%;
  }
  .DropZindex {
    z-index: 99999;
  }
  .HomeBannerSlider button.slick-arrow.slick-next {
    display: none !important;
  }

  .HomeBannerSlider .slick-dots {
    bottom: 20px;
  }

  .HomeBannerSlider .slick-dots li button:before {
    color: #fff;
    font-size: 8px;
  }

  .Home_Banner_Info {
    max-width: 90%;
  }

  .HomeBannerSlider {
    margin-top: 100px;
    color: black !important;
  }

  .note-text {
    font-size: 0.7rem;
    font-weight: 600;
    color: "#fff" !important;
  }
}

@media (max-width: 575px) {
  .HomeIndustries .col-lg-5 {
    width: 100%;
  }
  .Home_Banner_Info h1 {
    font-size: 32px;
  }
  .Home_Banner_item_img {
    min-height: calc(100vh - 200px);
    height: 600px;
    background-color: #fff;
  }
  .Home_Banner_item_img img {
    min-height: calc(100vh - 200px);
    height: 600px;
  }
}
@media (max-width: 380px) {
  .Home_Banner_Info h1 {
    font-size: 28px;
    margin-bottom: 12px;
  }
  .Home_Banner_item_img {
    min-height: calc(100vh - 200px);
    height: 650;
    background-color: #fff;
  }
  .Home_Banner_item_img img {
    min-height: calc(100vh - 200px);
    height: 650;
  }
  .Home_Banner_Info p {
    line-height: 20px;
  }

  .css-13cymwt-control {
    height: 50px !important;
    min-height: 50px !important;
  }

  .btn-homeSearch.btn {
    height: 50px;
  }
}
