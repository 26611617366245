/* Topbar */
.topbar {
  background: #043289;
  height: 35px;
}

.email_icon {
  margin-left: 20px;
}

.inventory_no {
  color: #fff !important;
}
.custom-offcanvas {
  overflow: hidden !important;
}

.inventory_no {
  min-height: 20px;
  min-width: 150px;
}

.offcanvas-body {
  /* overflow: hidden !important; */
  overflow-x: hidden !important;
}

@media (max-width: 768px) {
  .custom-offcanvas {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
}

.dropdown:hover .dropdown-menu {
  display: block;
  height: 26vh;
  width: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
.dropdown-menu a.dropdown-item:hover {
  background-color: #043289;
  color: #fff;
}
.dropdown-toggle-1 {
  color: #fff;
  background-color: transparent;
  border: none;
  font-weight: 500;
}

.ant-dropdown-placement-bottomLeft {
  z-index: 9999999999;
}

.new-dropdown {
  color: #fff;
  font-weight: 500;
}

.new-dropdown:hover {
  color: #fff;
}

.clearFilterBtn {
  width: 135px !important;
  /* width: 120px !important; */
  margin-left: -25px !important;
}

.filterBtn {
  /* margin: auto; */
  /* padding-left: 38px; */
}

.btn-blue-2 {
  color: #fff;
  font-size: 16px;
  padding: 6px 12px;
  line-height: 30px;
  background: #043289 !important;
  border-radius: 0;
  font-weight: lighter;
  font-weight: 600;
  transition: all 0.5s;
  height: 45px;
}

.sortBtn {
  /* width: 120px !important; */
  height: 45px;
}

.ourservice-btn {
  border: none;
}

.ourservice-btn:hover {
  color: #043189; /* Replace with your desired color */
}
.cartCount {
  background-color: #9d3426;
  color: white;
  font-size: 0.6rem;
  font-weight: 500 !important;
  padding: 4px 7px;
  border-radius: 1rem;
  position: absolute;
  margin-left: 1.5rem;
  margin-top: -15px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.header-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0;
  background-color: #043289;
  height: 50px;
  width: 100%;
}

.dropdown-menu.show {
  height: 60vh;
  width: 370px;
  overflow-y: auto;
  overflow-x: hidden;
}

.header-menu {
  background-color: #043289;
}

.header-menu-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* padding: 10px 0; */
  font-weight: bold;
  text-align: center;
  /* margin: 0 30px; */

  color: #fff;
  /* background-color: #043289; */
}

.ant-dropdown {
  z-index: 999999;
}

.header-menu ul {
  display: flex;
  list-style: none;
  text-align: center;
  padding: 0;
  margin: 0;
  align-items: center;
  /* justify-content: space-around; */
  gap: 200px;
}

.topbar ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
}

.mobile-search {
  display: none;
}

.topbar ul li:nth-last-child(3) {
  margin: 0 auto;
}

.TopBarFacebook {
  display: none;
}

.topbar ul li a,
.logOut {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  padding: 7px 0;
  cursor: pointer;
}

.topbar ul li a i {
  font-style: normal;
}

.topbar ul li a svg {
  font-size: 26px;
  font-weight: 300;
  margin-right: 6px;
}

header {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.5s;
  z-index: 100;
  box-shadow: 0 0 90px rgb(0 0 0 / 5%);
  background: #fff;
  z-index: 9999;
}

header.fixed {
  box-shadow: 0 1px 90px 0px #00000010;
  transition: all 0.5s;
  /* backdrop-filter: blur(31px);
    background: #ffffff35; */
}

header.fixed .navbar-brand {
  max-width: 170px;
  transition: all 0.5s;
}

.navbar-brand {
  max-width: 300px;
  transition: all 0.5s;
  display: block;
}

.navbar > .container,
.navbar-expand-lg .navbar-nav {
  align-items: center;
}

.navbar-brand img {
  width: 270px;
  height: auto;
  object-fit: contain;
}

div#navbarNav {
  width: 100%;
}

.navbar-nav {
  margin: 0 auto;
}

nav {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.search-bar {
  display: none;
}

.searchbar {
  display: none;
}

.searchbar-mobile {
  display: none;
}

/* Add this CSS to your stylesheet */
@media (min-width: 992px) {
  .search-bar.desktop {
    display: none;
  }

  .inventory_no {
    margin-right: 160px !important;
    color: #fff !important;
  }

  .navbar-brand img {
    width: 200px;
    height: auto;
    object-fit: contain;
  }
}

@media screen and (max-width: 787px) {
  .header-menu {
    display: none;
  }

  .navbar-brand img {
    width: 170px;
    height: auto;
    object-fit: contain;
  }

  header {
    position: absolute;
  }
  .mobile-search {
    display: block;
    width: 470px;
  }

  .dropdown-menu.show {
    height: 35vh;
  }

  .filterBtn {
    margin: 0;
    padding: 0;
  }

  .searchbar input {
    width: 250px;
    /* margin: 10px; */
  }

  .search-bar {
    width: 370px;
    margin: 10px;
  }

  .search_input input {
    width: 350px;
    margin: 10px;
  }

  .btn {
    width: 70px;
  }

  .btn-blue-2 .clearFilterBtn {
    width: 50% important;
  }

  .clearFilterBtn {
    width: 50% !important;
  }

  .sortBtn {
    width: 50% !important;
  }

  .closebtn {
    border: none;
    background: #fff;
    margin-right: 12px;
    float: right;
  }
}

.navbar-nav a {
  color: #000000;
  opacity: 0.6;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  transition: all 0.5s;
  margin: 0 15px;
}
.navbar-nav .dropdown-toggle {
  border: none !important;
  outline: none !important;
}
.navbar-nav a:hover,
.navbar-nav a.active,
.navbar-nav .dropdown-toggle:hover,
.navbar-nav .dropdown-toggle.active {
  opacity: 1;
  transition: all 0.5s;
  color: #043289;
}
.active.cart-header {
  background: #043289;
  color: #fff;
}
.active.cart-header .cartCount {
  background-color: #fff;
  color: #043289;
}
.header-btn-wrap {
  display: flex;
  align-items: center;
}

.header-btn-wrap li a {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #043289;
  background: rgb(4 50 137 / 10%);
  font-size: 22px;
  transition: all 0.5s;
  margin-left: 15px;
}

.header-btn-wrap li a:hover {
  background: #043289;
  color: #fff;
  transition: all 0.5s;
}

.header-search {
  position: relative;
}

.header-search input {
  width: 100%;
  position: absolute;
  height: 100%;
  right: 0;
  background: transparent;
  border: none;
  opacity: 0;
  cursor: pointer;
  z-index: 9;
  transition: all 0.5s;
}

.header-search input:focus {
  width: 250px;
  background: rgb(229 234 243);
  opacity: 1;
  right: 60px;
  transition: all 0.5s;
  padding: 0 0px 0 20px;
}

.header-search input:focus + a {
  z-index: 99;
}

/* Home Banner */
.HomeBanner-section {
  padding: 20px 0;
}

.homebanner-search {
  position: relative;
}

.homebanner-search input {
  width: 100%;
  height: 50px;
  border: none;
  padding: 0 15px 0 40px;
  font-size: 16px;
  font-weight: 400;
}

.homebanner-search a {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  font-size: 18px;
}

.homebanner-search input::placeholder {
  opacity: 0.5;
}

.form-select:focus {
  border-color: #0000;
  box-shadow: none;
}

.select-box {
  height: 50px;
  border: none;
  border-radius: 0;
}

.CustomDropDown .dropdown-menu a {
  display: block;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  padding: 4px 10px;
  transition: all 0.5s;
}

.CustomDropDown .dropdown-menu {
  min-width: 190px;
}

.CustomDropDown .dropdown-menu a:hover {
  background: #043189;
  color: #fff;
}

.dropdown-menu-mobile {
  display: none !important;
}

@media (max-width: 1199px) {
  .navbar-nav a {
    margin: 0 10px;
  }

  .navbar-brand {
    max-width: 250px;
  }
}

@media (max-width: 991px) {
  .offcanvas-header {
    justify-content: flex-end;
  }

  .navbar-brand {
    margin-right: auto;
  }

  .navbar-toggler {
    order: 1;
    padding: 0;
    border: none;
    width: 50px;
    height: 50px;
    background: rgba(4, 50, 137, 0.1);
    border-radius: 0;
    margin-left: 15px;
    position: relative;
  }

  .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
  }

  .navbar-brand {
    margin-right: auto;
  }

  .navbar-toggler .navbar-toggler-icon {
    background: #0000;
    width: 16px;
    height: 2px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 60%;
    transition: all 0.5s;
  }

  .navbar-toggler .navbar-toggler-icon:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    background: #043289;
    height: 2px;
    transform: rotate(45deg);
    transition: all 0.5s;
  }

  .navbar-toggler .navbar-toggler-icon:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    background: #043289;
    height: 2px;
    transform: rotate(-45deg);
    transition: all 0.5s;
  }

  .navbar-toggler.collapsed .navbar-toggler-icon {
    background: #043289;
    width: 16px;
    height: 2px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 60%;
    transition: all 0.5s;
  }

  .navbar-toggler.collapsed .navbar-toggler-icon:after {
    content: "";
    position: absolute;
    top: -8px;
    right: 0;
    width: 24px;
    background: #043289;
    height: 2px;
    transform: unset;
    transition: all 0.5s;
  }

  .navbar-toggler.collapsed .navbar-toggler-icon:before {
    content: "";
    position: absolute;
    bottom: -8px;
    right: 0;
    width: 8px;
    top: unset;
    background: #043289;
    height: 2px;
    transform: unset;
    transition: all 0.5s;
  }

  .offcanvas-body {
    transition: all 0.5s;
  }

  .navbar-nav a {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    opacity: 1 !important;
    padding: 15px 0;
    border-bottom: 1px solid #0002;
  }

  .CustomDropDown .dropdown-toggle::after {
    display: none;
  }

  .CustomDropDown.dropdown-menu {
    display: block !important;
  }

  .CustomDropDown .dropdown-menu {
    min-width: 100%;
    width: 100%;
    display: block !important;
    border: navajowhite;
    padding: 0;
    margin-top: 0;
    border-bottom: 1px solid #0001;
    padding-bottom: 10px;
    padding-left: 15px;
  }

  .CustomDropDown .dropdown-toggle {
    margin: 15px 10px 7px !important;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    opacity: 1 !important;
    padding: 0 !important;
  }

  .CustomDropDown .dropdown-menu a {
    border: navajowhite;
    position: relative;
  }

  .CustomDropDown .dropdown-menu a::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 4px;
    width: 5px;
    height: 5px;
    background: #000;
    border-radius: 100%;
    transform: translateY(-50%);
  }

  .offcanvas-body {
    overflow: auto;
  }

  .CustomDropDown:hover .dropdown-menu,
  .CustomDropDown .dropdown-menu {
    display: none !important;
  }

  .dropdown-menu.show.dropdown-menu-mobile {
    display: block !important;
  }

  .dropdown-menu.show.dropdown-menu-mobile a {
    display: block;
    font-size: 15px;
    padding: 5px 12px;
    font-weight: 500;
  }

  .dropdown-menu.show.dropdown-menu-mobile {
    border: navajowhite;
    padding-top: 0;
  }

  .offcanvas.offcanvas-end {
    transition: all 0.5s;
    width: 100%;
    max-width: 300px;
    z-index: 999999;
  }
}

.inventory_no {
  /* margin-right: 200px; */
}

@media (max-width: 767px) {
  .navbar-brand {
    max-width: 200px;
  }

  .TopBarFacebook {
    display: block;
  }

  .topbar ul li:nth-last-child(3) {
    margin: 0;
  }

  /* .topbar ul li a i {
      display: block;
    } */

  .inventory_no {
    /* margin-right: 50px; */
  }

  .topbar ul li a i {
    display: none;
  }

  /* .inventory_no a i {
      display: block;
    } */

  .topbar ul li a svg {
    margin: 0 10px 0 0;
  }

  .inventory_no a i {
    display: inline-block;
  }

  .topbar ul li:nth-last-child(1) {
    margin-left: auto;
  }
}

@media (max-width: 479px) {
  .header-btn-wrap li a {
    /* width: 40px; */
    min-width: 40px;
    height: 40px;
    margin-left: 8px;
  }

  .navbar-toggler {
    width: 40px;
    height: 40px;
    min-width: 40px;
    margin-left: 8px;
  }

  .navbar-brand {
    max-width: 150px;
  }

  .header-search input:focus {
    width: 170px;
    right: 42px;
  }

  .navbar > .container,
  .navbar-expand-lg .navbar-nav {
    flex-wrap: nowrap;
  }
}

.subMenus {
  position: absolute;
  top: 0;
  right: -97%;
  background: #fff;
  border: 1px solid #ccc;
  transform: translateY(30px);
  transition: all 0.5s;
  visibility: hidden;
  opacity: 0;
}

.submenuLink {
  position: relative;
}

.submenuLink:hover .subMenus {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.CustomDropDown:hover .dropdown-menu {
  display: block;
}
