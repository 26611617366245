.OurServices-Section {
  background: #fafafa;
  padding: 80px 0;
  margin-top: 0px;
}

.OurServices-Section h2 {
  color: #000000;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  letter-spacing: 0.01em;
  margin-bottom: 50px;
  text-align: center;
}

.client-logos {
  min-height: 60px; /* Ensure a minimum height */
  display: flex;
  align-items: center;
}

.client-logos-Items {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px; /* Explicit height prevents CLS */
  overflow: hidden;
}

.client-logos-Items img {
  object-fit: contain; /* Ensures images don’t stretch */
}

.service-box {
  background: #fff;
  border-radius: 10px;
  text-align: center;
  padding: 30px 20px;
  margin: 15px 0;
  transition: all 0.5s;
  height: calc(100% - 30px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service-box h6 {
  color: #043289;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  transition: all 0.5s;
}

.service-box p {
  color: rgba(39, 39, 39, 0.8);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px;
  transition: all 0.5s;
}

.service-box a {
  color: #272727;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}

.service-box a svg {
  font-size: 26px;
  margin-left: 5px;
  transition: all 0.5s;
}

.service-box:hover {
  background-color: #043289;
  color: #fff;
}

.service-box:hover h6,
.service-box:hover p,
.service-box:hover a,
.service-box:hover svg {
  color: #fff;
}
.overflowAuto {
  overflow: auto;
}
.client-logos {
  list-style: none;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 60px;
  padding: 80px 30px;
  max-width: 1170px;
  min-width: 1170px;
}

.client-logos li,
.client-logos-Items {
  margin: 0 auto;
  filter: grayscale(1);
  opacity: 0.4;
  transition: all 0.5s;
}

.client-logos li img,
.client-logos-Items img {
  width: 100%;
  object-fit: contain;
  object-position: center;
  height: 100%;
}
.client-logos li:hover,
.client-logos-Items:hover {
  opacity: 1;
  transition: all 0.5s;
  filter: unset;
}
/* .client-logosMobile{
    display: none !important;
} */
.client-logos-Items img {
  max-width: 100px;
  margin: 0 auto;
  vertical-align: inherit;
  height: 80px;
  object-position: center;
}
.slick-slider.client-logosMobile.client-logos.slick-initialized .slick-next {
  display: none !important;
}
@media (max-width: 1599px) {
  .OurServices-Section {
    margin-top: 0;
    padding: 60px 0;
  }

  .OurServices-Section h2 {
    margin-bottom: 40px;
  }
  .client-logos {
    padding: 60px 15px;
    max-width: 100%;
  }
  .client-logos-Items img {
    max-width: 110px;
  }
}

@media (max-width: 1429px) {
  .OurServices-Section {
    margin-top: 0;
    padding: 60px 0;
  }

  .service-box h6 {
    font-size: 20px;
  }

  .OurServices-Section h2 {
    margin-bottom: 40px;
  }
  .clientLogosHidden {
    overflow: hidden;
  }
}

@media (max-width: 1199px) {
  .client-logos {
    display: none;
  }
  .client-logosMobile {
    display: block !important;
    padding: 40px 0;
  }
  .client-logos-Items {
    height: 60px;
    max-width: 180px;
    text-align: center;
    padding: 0 15px;
    display: flex !important;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
  }
  .slick-slider.client-logosMobile.slick-initialized .slick-next {
    display: none !important;
  }

  .client-logos-Items img {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  .OurServices-Section {
    padding: 50px 0;
  }

  .OurServices-Section h2 {
    font-size: 28px;
    margin-bottom: 30px;
  }
  .service-box {
    padding: 25px 15px;
  }

  .service-box h6 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .service-box p {
    font-size: 14px;
    line-height: 21px;
  }

  .service-box a {
    font-size: 16px;
  }
}
@media (max-width: 991px) {
  .client-logos {
    padding: 60px 0;
  }
}
@media (max-width: 575px) {
  .service-box {
    margin: 7px 0;
    height: calc(100% - 14px);
  }
  .OurServices-Section h2 {
    font-size: 24px;
    line-height: initial;
  }
}
